import React from 'react'
import { Button } from 'antd'
import { GoogleLogin } from 'react-google-login'
import { FcGoogle } from 'react-icons/fc'
import { useDispatch } from 'react-redux'
import { googleLogin } from '../../../redux/Actions/AuthenticationAction'
import { useRouter } from 'next/router'

function LoginGoogle({ loading, title }) {
  const dispatch = useDispatch()
  const router = useRouter()
  const googleKey = '358344672076-399qjt993e3ps671gqo1upjfr9tujvv4.apps.googleusercontent.com'

  const responseGoogle = async (value) => {
    await dispatch(googleLogin(value.tokenId, router.query.returnUrl))
  }

  return (
    <GoogleLogin
      clientId={googleKey}
      onSuccess={responseGoogle}
      render={(renderProps) => (
        <Button
          loading={loading}
          onClick={renderProps.onClick}
          size="large"
          block
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <FcGoogle size={24} style={{ marginRight: '10px' }} />
          {title}
        </Button>
      )}
    />
  )
}

export default LoginGoogle
